import {OrgReg} from "../types/OrgReg";
import {buildOrgNumber} from "../types/ProcurementNoticeList";

export const findCompany = async (orgNumber: string) : Promise<OrgReg> => {
    let orgNum = buildOrgNumber(orgNumber).id
    console.log(`[findCompany] organization number '${orgNum}'`)
    try {
        let endpoint = "https://data.brreg.no/enhetsregisteret/api/enheter/" + orgNum
        const response = await window.fetch(endpoint, {
            method: 'GET', headers: {
                'content-type': 'application/vnd.brreg.enhetsregisteret.enhet.v1+json;charset=UTF-8',
            },
        })

        if (response.ok) {
            let eventResponse = await response.json();
            // console.log(JSON.stringify(eventResponse, null, 2))
            if (eventResponse !== undefined) {
                return eventResponse
            } else {
                console.log("[findCompany][WARN] No response")
                return Promise.reject(new Error(`No response`))
            }
        } else {
            console.log("[findCompany][ERROR] Error occurred while fetching company")
            return Promise.reject(new Error("Error occurred while fetching company"))
        }
    } catch (error: unknown) {
        console.log("[findCompany][ERROR] Error occurred during backend api call '/api/getMessageEvents'")
        return Promise.reject(error)
    }
};
