export interface ProcurementNoticeList {
    numHitsTotal: number
    numHitsAccessible: number
    hits: ProcurementNotice[]
}

export interface DoffinID {
    id: string
    published: string | null
    platform: string // enum PlatformID
}

export interface TedID {
    id: string
    coll: string | null
    no: string | null
    published: string | null
    noDoc: string | null
    validated: boolean
    platform: string // enum PlatformID
}

export interface ProcurementID {
    id: string
    platform: string // enum PlatformID
}

export function noticeID(notice: ProcurementNotice): string | null {
    if (notice.doffinID)
        return `${notice.doffinID.platform}-${notice.doffinID.id}`
    if (notice.tedID)
        return `${notice.tedID.platform}-${notice.tedID.id}`
    return null
}

export interface ProcurementNotice {
    doffinID: DoffinID | null
    tedID: TedID | null
    norskLysingsBladID: ProcurementID[]
    doffinSearchID: ProcurementID[]
    procurementIDs: ProcurementID[]
    customID: string[] | null
    submissionDeadline: string | null
    buyers: Buyer[]
    informationProvider: any
    title: LocalizedString
    metaData: NoticeMetaData
    doffinReferences: DoffinID[] | null
    tedReferences: TedID[] | null
    links: string[] | null
    mailtoDomains: string[] | null
    productClassification: ProductClassification
    contractingSystemFA: boolean | null
    contractingSystemDPS: boolean | null
    contractingSystemPC: boolean | null
    dividedIntoLots: boolean | null
    numberOfLots: number | null
    location: Location
    amounts: Amounts | null
    period: Period | null
    lots: Lot[] | null
    wonLots: WonLot[] | null
    offersReceived: number | null
    recordMetaData: RecordMetaData
    country: NutsCode | null
    description: NoticeDescription | null
    note: LocalizedString | null
    status: string | null
}

export interface Buyer {
    id: OrgIdentifier
    address: Place[] | null
    contactPoint: ContactPoint | null
    source: string[] | null // enum OrgSource
}

export interface ContactPoint {
    name: string | null
    email: string | null
    telephone: string | null
    fax: string | null
}

export interface OrgIdentifier {
    name: LocalizedString
    officialName: string | null
    nationalID: OrgNumber | null
    profileID: OrgProfileID[] | null
    orgID: OrgID
    source: string[] | null // enum OrgSource
}

export interface OrgNumber {
    id: string
    registerID: string // enum OrgNumberRegister
}

export function buildOrgNumber(num: string): OrgNumber {
    if (!num.includes("-"))
        return {id : num, registerID: "BRREG"} as OrgNumber
    let [registerID, ...rest] = num.split('-')
    let id = rest.join('-')
    return {id : id, registerID: registerID} as OrgNumber
}

export interface OrgProfileID {
    id: string
    platform: string
}

export interface OrgID {
    id: string
    documentID: DocumentID | null
}

export interface DocumentID {
    id: string
}

export interface Place {
    department: string | null
    street: string | null
    town: string | null
    postcode: string | null
    country: TextCode | null
    countrySubEntity: TextCode | null
    region: string | null
    desc: LocalizedString | null
    kinds: string[] // enum AddressKind
}

export interface TextCode {
    code: string
    listName: string
    text: string
}

export interface NoticeMetaData {
    formTypes: FormType[]
    procedureType: string[] // enum NoticeProcedureType
    contractType: ContractType[]
    legalBasis: LegalBasis[]
}

export interface FormType {
    code: string
    label: string
}

export interface ContractType {
    code: string
    label: string
}

export interface LegalBasis {
    code: string
    label: string
}

export interface ProductClassification {
    mainCpv: CpvCode[] | null
    additionalCpvs: CpvContextList | null
    commodityCodes: CommodityContextList | null
}

export interface CommodityContextList {
    contexts: CommodityContext[]
}

export interface CommodityContext {
    codes: CommodityCode[]
    type: string | null // enum CommodityContextType
}

export interface CommodityCode {
    id: string
    label: LocalizedString
}

export interface CpvContextList {
    contexts: CpvContext[]
}

export interface CpvContext {
    codes: CpvCode[]
    type: string | null // enum CpvContextType
}

export interface CpvCode {
    id: CpvID
    label: LocalizedString | null
    version: string
    correspondingCpv: CpvID | null
}

export interface CpvID {
    code: string
}

export interface Location {
    nutsContextList: NutsContextList | null
    locationContextList: LocationContextList | null
    locationDescription: LocationDescription | null
}

export interface NutsContextList {
    contexts: NutsContext[]
}

export interface NutsContext {
    codes: NutsCode[]
    type: string | null // enum NutsContextType
}

export interface LocationContextList {
    contexts: LocationContext[]
}

export interface LocationContext {
    codes: LocationCode[]
    type: string | null // enum LocationContextType
}

export interface LocationCode {
    id: string
    label: LocalizedString
}

export interface LocationDescription {
    contexts: string[]
}

export interface Amounts {
    estimatedOverallContract: Amount | null
    frameworkMaximum: Amount | null
    estimatedValueText: string | null
}

export interface Amount {
    value: number | null
    currency: string | null
    localizedText: string | null
    formattedValue: string | null
    excludingVAT: boolean | null
}

export interface Period {
    startDate: string | null
    endDate: string | null
    duration: string | null
}

export interface Lot {
    id: string
    number: string | null
    customID: string | null
    title: LocalizedString | null
    description: LocalizedString | null
    amount: Amount | null
    productClassification: ProductClassification | null
}

export interface WonLot {
    id: string
    lotNumber: string
    contractNumber: string | null
    awardDate: string | null
    winner: Winner[] | null
    offersReceived: string | null
    isLikelySubContracted: boolean | null
}

export interface Winner {
    id: OrgIdentifier
    address: Place[]
    contactPoint: ContactPoint | null
    source: string[] // enum OrgSource
}

export interface LocalizedString {
    list: LocalizedText[]
}

export interface LocalizedText {
    languageID: string
    text: string
}

export interface RecordMetaData {
    archiveName: string[]
    pathInArchive: string[]
    schemaName: string[]
    note: string[] | null
}

export interface NutsCode {
    id: string
    label: LocalizedString | null
}

export interface NoticeDescription {
    shortContractDescription: string | null
    economicOperatorsPersonalSituation: string | null
    totalQuantityOrScope: string | null
}
